<!--OWL Picker-->
<ng-container *ngIf="appService.UserProfile.DatePickerType == 1">
  <!--Calendar and Timer-->
  <mat-form-field class="elso-datetimepicker" *ngIf="!pickerType" appearance="fill" [owlDateTimeTrigger]="dt1">
    <input matInput readonly sectionName="this.sectionName" [id]="this.id" otherDateComparsion="this.otherDateComparsion"
           [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date time" [formControl]="model" (ngModelChange)="changeEvent($event)">
    <owl-date-time #dt1 [hour12Timer]="dateTimeService.twelveHourFormat"></owl-date-time>
    <span style=" position: absolute; right: -12px; bottom: 10px;" class="trigger" [owlDateTimeTrigger]="dt1">
      <mat-icon class="mat-icon" matTooltip="Picker" matTooltipPosition="above" color="primary">calendar_today</mat-icon>
    </span>
  </mat-form-field>
  <!--Just Calendar or Timer-->
  <mat-form-field class="elso-datetimepicker" *ngIf="pickerType == 'calendar' || pickerType == 'timer'" appearance="fill" [owlDateTimeTrigger]="dt1">
    <input matInput readonly sectionName="this.sectionName" [id]="this.id" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="Date time" [formControl]="model" (ngModelChange)="changeEvent($event)">
    <owl-date-time #dt1 [startView]="'multi-years'"
                   (yearSelected)="chosenYearHandler($event)"
                   (monthSelected)="chosenMonthHandler($event)"
                   (daySelected)="chosenDayHandler($event, dt1)" dateFormat="'MM/DD/YYYY'"
                   [pickerType]="pickerType" [hour12Timer]="dateTimeService.twelveHourFormat"></owl-date-time>
    <span style=" position: absolute; right: -12px; bottom: 10px;" class="trigger" [owlDateTimeTrigger]="dt1">
      <mat-icon class="mat-icon" matTooltip="Picker" matTooltipPosition="above" color="primary">calendar_today</mat-icon>
    </span>
  </mat-form-field>
</ng-container>
<!--END OF OWL Picker-->

<!--OWL TYPE Picker-->
<ng-container *ngIf="appService.UserProfile.DatePickerType == 3">
  <!--<label class="control-label">Date :</label>
  <input [formControl]="model" [owlDateTime]="fromdatepicker" placeholder="Date Time" class="form-control" name="fromDate">
  <owl-date-time #fromdatepicker></owl-date-time>
  <span [owlDateTimeTrigger]="fromdatepicker" class="trigger">
    icon
    <i class="fa fa-calendar"></i>
  </span>-->
  <!--<h2>{{model.value | json}}</h2>-->

  <!--Calendar and Timer-->
  <mat-form-field class="elso-datetimepicker" *ngIf="!pickerType" appearance="fill">
    <input matInput sectionName="this.sectionName" [id]="this.id" otherDateComparsion="this.otherDateComparsion"
           [owlDateTime]="dt1" placeholder="Date time" [formControl]="model" (ngModelChange)="changeEvent($event)">
    <owl-date-time #dt1 [hour12Timer]="dateTimeService.twelveHourFormat"></owl-date-time>
    <span style=" position: absolute; right: -12px; bottom: 10px;" class="trigger" [owlDateTimeTrigger]="dt1">
      <mat-icon class="mat-icon" matTooltip="Picker" matTooltipPosition="above" color="primary">calendar_today</mat-icon>
    </span>
  </mat-form-field>
  <!--Just Calendar or Timer-->
  <mat-form-field class="elso-datetimepicker" *ngIf="pickerType == 'calendar' || pickerType == 'timer'" appearance="fill">
    <input matInput sectionName="this.sectionName" [id]="this.id" [owlDateTime]="dt1" placeholder="Date time" [formControl]="model" (ngModelChange)="changeEvent($event)">
    <owl-date-time #dt1 [startView]="'multi-years'"
                   (yearSelected)="chosenYearHandler($event)"
                   (monthSelected)="chosenMonthHandler($event)"
                   (daySelected)="chosenDayHandler($event, dt1)" dateFormat="'MM/DD/YYYY'"
                   [pickerType]="pickerType" [hour12Timer]="dateTimeService.twelveHourFormat"></owl-date-time>
    <span style=" position: absolute; right: -12px; bottom: 10px;" class="trigger" [owlDateTimeTrigger]="dt1">
      <mat-icon class="mat-icon" matTooltip="Picker" matTooltipPosition="above" color="primary">calendar_today</mat-icon>
    </span>
  </mat-form-field>
  <div class="hard-error" *ngIf="model.hasError('owlDateTimeParse')">Date value or format is not valid!</div>
</ng-container>
<!--END OF OWL TYPE Picker-->
<!--OLD Picker-->
<div class="datetimepicker" *ngIf="appService.UserProfile.DatePickerType == 2">
  <div style="display:inline-block;">

    <!--DATE PICKER-->
    <ng-container *ngIf="showDatePicker">
      <!--Month-->
      <ng-container *ngIf="showMonthPicker">
        <ng-container *ngIf="showMonthInput">
          <input class="pickerPart" [(ngModel)]="monthModel" type="text" name="month" id="month" (keyup)="filter('month')" (keydown.Tab)="closeInput('month', 'tab')" (focusout)="closeInput('month')" />
        </ng-container>
        <span *ngIf="showMonthLabel">
          <a href="#" class="pickerDisplayedValue" name="monthHref" title="Select month" (click)="$event.preventDefault(); onPickerClick('month')">{{selectedMonth.name}}</a>
          /
        </span>
        <div class="dp-list" *ngIf="showMonthList">
          <div *ngFor="let obj of filteredMonths" (mousedown)="onValueClick('month', obj)" style="cursor:pointer;">{{obj.name}}</div>
        </div>
      </ng-container>
      <!--Day-->
      <ng-container *ngIf="showDayPicker">
        <ng-container *ngIf="showDayInput">
          <input class="pickerPart" [(ngModel)]="dayModel" type="text" name="day" id="day" (keyup)="filter('day')" (keydown.Tab)="closeInput('day', 'tab')" (focusout)="closeInput('day')" />
        </ng-container>
        <span *ngIf="showDayLabel">
          <a href="#" class="pickerDisplayedValue" name="dayHref" title="Select day" (click)="$event.preventDefault(); onPickerClick('day')">{{selectedDay.name}}</a>
          /
        </span>
        <div class="dp-list" *ngIf="showDayList">
          <div *ngFor="let obj of filteredDays" (mousedown)="onValueClick('day', obj)" style="cursor:pointer;">{{obj.name}}</div>
        </div>
      </ng-container>
      <!--Year-->
      <ng-container *ngIf="showYearPicker">
        <ng-container *ngIf="showYearInput">
          <input class="pickerPart" [(ngModel)]="yearModel" type="text" name="year" id="year" (keyup)="filter('year')" (keydown.Tab)="closeInput('year', 'tab')" (focusout)="closeInput('year')" />
        </ng-container>
        <span *ngIf="showYearLabel">
          <a href="#" class="pickerDisplayedValue" name="yearHref" title="Select year" (click)="$event.preventDefault(); onPickerClick('year')">{{selectedYear.name}}</a>
          &nbsp;
        </span>
        <div class="dp-list" *ngIf="showYearList">
          <div *ngFor="let obj of filteredYears" (mousedown)="onValueClick('year', obj)" style="cursor:pointer;">{{obj.name}}</div>
        </div>
      </ng-container>
    </ng-container>

    <!--TIME PICKER-->
    <ng-container *ngIf="showTimePicker">
      <!--Hour-->
      <ng-container *ngIf="showHourPicker">
        <ng-container *ngIf="showHourInput">
          <input class="pickerPart" [(ngModel)]="hourModel" type="text" name="hour" id="hour" (keyup)="filter('hour')" (keydown.Tab)="closeInput('hour', 'tab')" (focusout)="closeInput('hour')" />
        </ng-container>
        <span *ngIf="showHourLabel">
          <a href="#" class="pickerDisplayedValue" name="hourHref" title="Select hour" (click)="$event.preventDefault(); onPickerClick('hour')">{{selectedHour.name}}</a>
          :
        </span>
        <div class="dp-list" *ngIf="showHourList">
          <div *ngFor="let obj of filteredHours" (mousedown)="onValueClick('hour', obj)" style="cursor:pointer;">{{obj.name}}</div>
        </div>
      </ng-container>
      <!--Minute-->
      <ng-container *ngIf="showMinutePicker">
        <ng-container *ngIf="showMinuteInput">
          <input class="pickerPart" [(ngModel)]="minuteModel" type="text" name="minute" id="minute" (keyup)="filter('minute')" (keydown.Tab)="closeInput('minute', 'tab')" (focusout)="closeInput('minute')" />
        </ng-container>
        <span *ngIf="showMinuteLabel">
          <a href="#" class="pickerDisplayedValue" name="minuteHref" title="Select minute" (click)="$event.preventDefault(); onPickerClick('minute')">{{selectedMinute.name}}</a>
          &nbsp;
        </span>
        <div class="dp-list" *ngIf="showMinuteList">
          <div *ngFor="let obj of filteredMinutes" (mousedown)="onValueClick('minute', obj)" style="cursor:pointer;">{{obj.name}}</div>
        </div>
      </ng-container>
      <!--AM PM-->
      <ng-container *ngIf="showAmPmPicker">
        <ng-container *ngIf="showAmPmInput">
          <input class="pickerPart" [(ngModel)]="ampmModel" type="text" name="ampm" id="ampm" (keyup)="filter('ampm')" (keydown.Tab)="closeInput('ampm', 'tab')" (focusout)="closeInput('ampm')" />
        </ng-container>
        <span *ngIf="showAmPmLabel">
          <a href="#" class="pickerDisplayedValue" name="ampmHref" title="Select ampm" (click)="$event.preventDefault(); onPickerClick('ampm')">{{selectedAmPm.name}}</a>
        </span>
        <div class="dp-list" *ngIf="showAmPmList">
          <div *ngFor="let obj of filteredAmPms" (mousedown)="onValueClick('ampm', obj)" style="cursor:pointer;">{{obj.name}}</div>
        </div>
      </ng-container>
    </ng-container>

  </div>
</div>
<!--END OF OLD Picker-->
&nbsp;&nbsp;<mat-icon style="display:inline-block;" *ngIf="model.value && disablePickerReset != 'true'" matTooltip="Reset value" matTooltipPosition="above" color="primary" (click)="resetDatePickerValue()">remove_circle</mat-icon>

<!--ERRORS-->

<div class="hard-error" *ngFor="let error of HardErrors">{{error.ErrorMessage}}</div>
<div class="soft-error" *ngFor="let error of SoftErrors">{{error.ErrorMessage}}</div>
<!--was places inside owl-date-time of Just Calendar or Timer but then (ngModelChange) for time picker wont work
    (yearSelected)="chosenYearHandler($event)"
    (monthSelected)="chosenMonthHandler($event)"
    (daySelected)="chosenDayHandler($event, dt1)"-->
