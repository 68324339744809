// date-time.service
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class DateTimeFormatService
{
  private _format: string;
  private _locale: string;
  private _twelveHourFormat: boolean;

  public constructor()
  {
    //console.log(localStorage.getItem('cultureSettings'));
    //commented on 04/24/2024
    //var f = "MMMM D YYYY hh:mm a"; 
    //var f = "M/D/YYYY hh:mm a";
    //var l = "en-US";
    //var t = true;
    ///////////////////////
    if (localStorage.getItem('cultureSettings') != null) {
      var cultureSettings = localStorage.getItem('cultureSettings').split('|');
      //console.log(cultureSettings);
      var l = cultureSettings[0];
      var f = cultureSettings[1];
      var t = (cultureSettings[2] === 'true');
      //console.log(f);
      this._format = f;
      this._locale = l;
      this._twelveHourFormat = t;
    }
     //commented on 04/24/2024
    //this._format = f;
    //this._locale = l;
    //this._twelveHourFormat = t;
    ///////////////////////
  }

  public testCallFromDatePicker() {
    this._format = "MMMM D YYYY";
  }

  public get format(): string
  {
    return this._format;
  }
  public set format(value: string)
  {
    //console.log(`Setting format to '${value}'`);

    this._format = value;
  }

  public get locale(): string
  {
    return this._locale;
  }  
  public set locale(value: string)
  {
    //console.log(`Setting locale to '${value}'`);

    this._locale = value;
  }

  public get twelveHourFormat(): boolean
  {
    return this._twelveHourFormat;
  }

  public set twelveHourFormat(value: boolean) {
    //console.log(`Setting twelveHourFormat to '${value}'`);

    this._twelveHourFormat = value;
  }
}
