<div [hidden]="appService.isLoading | async" style="height: 1000px; overflow-y: scroll; ">
  <br /><br /> <br />
  <div style="border: 5px solid #3F51B5; width: fit-content; border-radius: 15px; padding: 3%; margin: auto;">
    <form class="example-form" autocomplete="off" (submit)="login()">
      <img style="width: 200px;" class="" src="../assets/images//elso-logo-big.png">
      <br />
      <br />
      <br />
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Username</mat-label>
          <input matInput type="text" name="Username" [(ngModel)]="loginUserModel.Username">
        </mat-form-field>
        <br /> <br />
        <mat-form-field appearance="fill">
          <mat-label>Password</mat-label>
          <input matInput type="password" name="Password" [(ngModel)]="loginUserModel.Password">
        </mat-form-field>
        <br />
        <br />
        <button type="submit" color="primary" mat-raised-button>Login</button>
      </div>
    </form>
  </div>
  <div id="footerCopyRight" style="margin-top: 20px;">
    © {{currentYear}} - Extracorporeal Life Support Organization, Ann Arbor, Michigan, USA. (Registry version - {{appService.registryVersion}})
  </div>
</div>


